import React from "react";
import { Layout, SEO } from "../components/structure"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Content, Hero } from "../components/blocks"

const SiteMapPage = () => {

  const data = useStaticQuery(graphql`
    query {
      allSitePage(sort: {fields: path, order: ASC}) {
        nodes {
          path
        }
      }
    }
   `);

  const { nodes: pages } = data.allSitePage;

  return (
    <Layout>
      <SEO
        title="Loyalty Solutions Provider"
        description={
          "Points helps to make loyalty programs more valuable and engaging."
        }
      />
      <Hero title={'Sitemap'} />
      <Content>
        <ul>
          { pages.map(({path}) => { return <SiteMapItem path={path} /> }) }
        </ul>
      </Content>
    </Layout>
  )
}

const SiteMapItem = ({path}) => (
  <li><Link style={{ color: '#000' }} to={path}>{ path }</Link></li>
)



export default SiteMapPage;
